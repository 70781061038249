<template>
  <form class="login-form" @submit.prevent="login()" novalidate="novalidate" ref="root">
    <div class="form-group"
      :class="{ error: loginForm.errors.email }">
      <label>Email</label>
      <input ref="email" type="email" class="flat-control input-control"
        v-model.trim="loginForm.fields.email"
        @change="validateForm('email')" />
      <div class="error-msg-contain">
        <div v-if="loginForm.errors.email" class="error-msg">
          {{ loginForm.errors.email }}
        </div>
      </div>
    </div>
    <div v-if="!forgot" class="form-group"
      :class="{ error: loginForm.errors.password }">
      <label>Password</label>
      <password-field ref="password" inputClass="flat-control input-control"
        v-model.trim="loginForm.fields.password"
        @change="validateForm('password')" />
      <div class="error-msg-contain">
        <div v-if="loginForm.errors.password" class="error-msg">
          {{ loginForm.errors.password }}
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-primary mx-auto d-block login-btn position-relative">{{forgot ? 'Submit' : 'Login'}} <loader v-if="loading.login" type="inside" /></button>

    <p class="text-center">
      Don't have an account?
      <a href="#" @click.prevent="register" class="font-weight-600 color-primary-active vertical-align-top">Sign Up</a>
    </p>
    <p class="text-center">
      <button type="button" @click="forgot = !forgot" class="btn-link color-primary text-underline">
        {{forgot ? 'Back to Sign In' : 'Forgot your password?'}}
      </button>
    </p>
  </form>
</template>
<script>
import PasswordField from "@/components/PasswordField.vue";
import { smoothScrollTo, regex, loginSuccessHandler } from "@/utils";

function getDefaultLoginForm() {
  return {
    fields: {
      email: "",
      password: ""
    },
    errors: {
      email: false,
      password: false
    },
  };
}

export default {
  components: {
    PasswordField
  },
  props: {
    value: {
      type: [Boolean, String],
      default: false,
    },
    oauth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loginForm: getDefaultLoginForm(),
      loading: {
        login: false
      },
      forgot: false
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    register() {
      if (this.$route.path == '/register') {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 300);
      } else {
        this.$router.push("/register");
      }
      this.close();
    },
    validateForm(field) {
      const isAll = !field;
      let f = "email";
      if (isAll || field == f) {
        if (!this.loginForm.fields[f]) {
          this.loginForm.errors[f] = "Please enter your email";
        } else if (regex.email.test(this.loginForm.fields[f])) {
          this.loginForm.errors[f] = false;
        } else {
          this.loginForm.errors[f] = "Please enter a valid email";
        }
      }
      f = "password";
      if (isAll || field == f) {
        if (!this.loginForm.fields[f]) {
          this.loginForm.errors[f] = "Please enter your password";
        } else {
          this.loginForm.errors[f] = false;
        }
      }
    },
    login() {
      this.validateForm();
      if (
        Object.keys(this.loginForm.fields).some(
          (k) => this.loginForm.errors[k] && (k != "password" || !this.forgot)
        )
      ) {
        // form has errors
        // this.$toast.error("Some fields have errors");
        this.$nextTick(() => smoothScrollTo(this.$refs.root.querySelector(".form-group.error"), -80, true));
      } else {
        // validation successful
        this.loading.login = true;
        const errorHandler = (err) => {
            console.log(err);
            this.$toast.error(err.formattedError);
            this.loading.login = false;
          };
          if (this.forgot) {
            this.$axios
              .post(`${process.env.VUE_APP_API_URL}/api/forgotPassword`, {
                email: this.loginForm.fields.email
              })
              .then(() => {
                this.close();
                this.$toast.success("You will receive an email if account exists!");
                this.loading.login = false;
              })
              .catch(errorHandler);
          } else {
            this.$axios
              .post(`${process.env.VUE_APP_API_URL}/api/userLogin`, {
                username: this.loginForm.fields.email,
                password: this.loginForm.fields.password,
                temp_userID: this.$root.authService.userId
              })
              .then((data) => {
                return this.$root.authService.handleLogin(data.data);
              })
              .then(() => {
                return this.$root.cartService.getCart();
              })
              .then((res) => {
                this.close();
                if (this.$root.authService.verificationPending) {
                  this.$router.push("/verification-pending");
                } else {
                  if (this.oauth) {
                    this.$root.vendastaService.authorize().catch(err => {
                      console.log(err);
                      this.$toast.error('Unable to login!');
                    });
                  } else {
                    loginSuccessHandler.apply(this, [res]);
                    this.$toast.success("Login successful!");
                  }
                }
                this.loading.login = false;
              })
              .catch(errorHandler);
          }
      }
    },
  },
  watch: {
    value() {
      if (this.value) {
        this.forgot = false;
        this.loginForm = getDefaultLoginForm();
        if (typeof(this.value) == "string" && this.value.indexOf('@') != -1) {
          this.loginForm.fields.email = this.value;
          setTimeout(() => {
            const ref = this.$refs.password && this.$refs.password.$el && this.$refs.password.$el.querySelector('input');
            if (ref) {
              ref.focus();
            }
          }, 150);
        } else {
          setTimeout(() => this.$refs.email && this.$refs.email.focus(), 150);
        }
      }
    },
    forgot() {
      this.$emit('forgot', this.forgot);
    },
  }
}
</script>
<style scoped>
.login-form .login-btn {
  min-width: 200px;
  margin-bottom: 1.5em;
  margin-top: 3em;
}

.login-form p {
  font-size: 1.25em;
  margin-bottom: 0.75em;
}

@media (max-width: 575px) {
  .login-form .login-btn {
    font-size: 1.1666em;
    margin-top: 1.4285em;
    margin-bottom: 1.0714em;
    width: calc(100% - 10px);
  }
  
  .login-form p {
    font-size: 0.84em;
    margin-bottom: 5px;
  }
}
</style>