<template>
  <div class="login-page padding-sparse-page-2 min-height-header">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-7 order-1 order-md-0">
        <div class="island">
          <h1>Grow Lifetime Values.<br />And Lifelong Customers.</h1>
          <p class="subtitle text-center mt-3 mt-sm-4 mb-5">Powered by predictions for customer acquisitions, engagement, and retention.</p>
          <img class="img-fluid mx-auto" src="/images/laptop-lady.png" alt="working on a laptop" />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-5 order-0 order-md-1 mb-5 mb-md-0">
        <img class="logo mb-4 mb-sm-5" src="/images/geofactor.png" alt="geofactor full logo" />
        <h2 class="text-center mb-4 mb-sm-5">{{title}}</h2>
        <div class="login-container">
          <LoginForm @forgot="forgot = $event" :oauth="oauth" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { authService } from "@/services/auth.service";
import { vendastaService } from "@/services/vendasta.service";
import LoginForm from "@/components/LoginForm.vue";
import { EventBus, EventTypes } from "@/event-bus";

export default {
  name: "LoginPopup",
  props: {
    value: [Boolean, String],
    oauth: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LoginForm,
  },
  beforeRouteEnter(to, from, next) {
    if (authService.isAuthenticated) {
      if (to.path == '/oauth-login') {
        EventBus.$emit(EventTypes.GLOBAL_LOADER, true);
        vendastaService.authorize().catch(err => {
          next({ path: "/", replace: true });
          console.log(err);
          EventBus.$emit(EventTypes.GLOBAL_MESSAGE, { title: "Unable to login", message: "Please try again" });
          EventBus.$emit(EventTypes.GLOBAL_LOADER, false);
        });
      } else {
        next({ path: "/", replace: true });
      }
    } else {
      next();
    }
  },
  data() {
    return {
      forgot: false,
    };
  },
  computed: {
    title() {
      return this.forgot ? 'Forgot Password' : 'Sign In';
    }
  },
}
</script>
<style scoped>
.login-container {
  max-width: 40em;
  width: 100%;
  margin: 0 auto;
}

.login-page {
  padding: 2em 4em;
  width: 100%;
  position: relative;
}

.login-page .island {
  padding: 8%;
  background-color: #E0F1F6;
  border-radius: 3.142em;
  color: #202020;
  text-align: center;
}

.login-page .island img {
  max-width: 90%;
}

.login-page h1 {
  font-size: 3.142em;
  text-align: center;
  margin-bottom: 0.3em;
  font-weight: 600;
  line-height: 1.28;
}

.login-page .subtitle {
  font-size: 1.71em;
  font-weight: 400;
  margin-bottom: 2em;
}

.login-page .logo {
  width: 24.142em;
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto 4.142em;
}

.login-page h2 {
  font-size: 2.285em;
  color: #202020;
  font-weight: 600;
}

@media (max-width: 991px) {
  .login-page {
    font-size: 0.85em;
  }

  .login-page .login-container {
    font-size: 1rem;
  }
}

@media (max-width: 767px) {
  .login-page {
    font-size: 0.7em;
    padding-bottom: 20em;
  }
}

@media (max-width: 575px) {
  .login-page {
    padding: 2.5em 15px 15em;
  }

  .login-page h1 {
    font-size: 2.142em;
  }

  .login-page .subtitle {
    font-size: 1.71em;
  }
}
</style>