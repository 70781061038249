<template>
  <div class="pwd-field control-container" :class="{focus: isFocussed}">
    <input
      :type="showOldPassword ? 'text' : 'password'"
      v-model="valueActual"
      @input="onEvent('input', $event)"
      :class="inputClass"
      @change="onEvent('change', $event)"
      @focus="isFocussed = true"
      @blur="isFocussed = false"
    />
    <a href="#" @click.prevent="showOldPassword = !showOldPassword" class="toggle-icon">
      <svg v-if="showOldPassword" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
          d="M8.073 12.194L4.212 8.333c-1.52 1.657-2.096 3.317-2.106 3.351L2 12l.105.316C2.127 12.383 4.421 19 12.054 19c.929 0 1.775-.102 2.552-.273l-2.746-2.746C9.811 15.88 8.174 14.243 8.073 12.194zM12.054 5c-1.855 0-3.375.404-4.642.998L3.707 2.293 2.293 3.707l18 18 1.414-1.414-3.298-3.298c2.638-1.953 3.579-4.637 3.593-4.679L22.107 12l-.105-.316C21.98 11.617 19.687 5 12.054 5zM13.96 12.546c.187-.677.028-1.439-.492-1.96s-1.283-.679-1.96-.492L10 8.586C10.602 8.222 11.3 8 12.054 8c2.206 0 4 1.794 4 4 0 .754-.222 1.452-.587 2.053L13.96 12.546z"
          fill="currentColor"
        />
      </svg>
      <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
        <path d="M0,0H15V15H0Z" fill="none" />
        <path
          d="M7.75,4A7.287,7.287,0,0,0,1,8.125,7.287,7.287,0,0,0,7.75,12.25,7.287,7.287,0,0,0,14.5,8.125,7.287,7.287,0,0,0,7.75,4Zm0,6.875a2.923,2.923,0,0,1-3.068-2.75A2.923,2.923,0,0,1,7.75,5.375a2.923,2.923,0,0,1,3.068,2.75A2.923,2.923,0,0,1,7.75,10.875Zm0-4.4a1.751,1.751,0,0,0-1.841,1.65A1.751,1.751,0,0,0,7.75,9.775a1.751,1.751,0,0,0,1.841-1.65A1.751,1.751,0,0,0,7.75,6.475Z"
          transform="translate(-0.25 -1)"
          fill="currentColor"
        />
      </svg>
    </a>
  </div>
</template>
<script>
export default {
  name: "PasswordField",
  props: {
    value: {
      type: String,
      default: ""
    },
    inputClass: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showOldPassword: false,
      valueActual: this.value,
      isFocussed: false
    }
  },
  methods: {
    onEvent(name, event) {
      const target = event.target || event.srcElement;
      this.$emit(name, target.value);
      this.$emit(`native${name}`, event);
    }
  },
  watch: {
    value() {
      this.valueActual = this.value;
    }
  }
}
</script>
<style scoped>
.pwd-field {
  position: relative;
}

.pwd-field input {
  padding-right: 3.125rem;
}

.pwd-field .toggle-icon {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0 1.25em 0 0.625em;
  display: flex;
  align-items: center;
}

.pwd-field .toggle-icon svg {
  width: 1.25em;
  height: 1.25em;
  opacity: 0.5;
}
</style>